<template>
  <VOverlay
    :value="loading"
    :z-index="zIndex"
    :opacity="opacity"
    :color="color"
  >
    <VProgressCircular
      indeterminate
      size="64"
      :color="loadingColor"
    />
  </VOverlay>
</template>

<script>
  export default {
    name: 'CustomLoading',

    components: {},
    props: {
      zIndex: {
        type: String,
        default: '1',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      loadingColor: {
        type: [Boolean, String],
        default: 'primary',
      },
      opacity: {
        type: [Boolean, String],
        default: '1',
      },
      color: {
        type: [Boolean, String],
        default: 'background',
      },

    },
  }
</script>

<style>

</style>
