// Utilities
import { make } from 'vuex-pathify'

import { setCookie, getCookie } from '@/util/cookies'
// eslint-disable-next-line no-unused-vars
import { genericRequest, genericMockRequest } from '@/api/modules'
import { getReportes } from '@/routes/reportes'
const state = {
  dark: getCookie('mc_front_dark_mode'),
  loaded: false,
  proveedor: {},
  admin: {},
  clienteInterno: {},
  cuit: '',
  avatar: '',
  name: '',
  reportes: {},
  group: null,
}

const mutations = make.mutations(state)

const actions = {
  // get user info
  getUserInfo ({ commit, state, dispatch }) {
    commit('SET_CUIT', '')
    commit('SET_NAME', '')
    commit('SET_PROVEEDOR', {})
    commit('SET_CLIENTE_INTERNO', {})
    commit('SET_ADMIN', {})
    commit('SET_GROUP', null)
    const {
      cuit,
      proveedor,
      admin,
      cliente_interno: clienteInterno,
    } = getCookie()
    commit('SET_CUIT', cuit)
    if (proveedor) {
      commit('SET_PROVEEDOR', { ...proveedor, name: proveedor.razsoc })
      commit('SET_NAME', proveedor.razsoc)
      commit('SET_GROUP', 'proveedor')
    }
    if (admin) {
      commit('SET_ADMIN', {
        ...admin,
        name: `${admin.nombre} ${admin.apellido}`,
      })
      commit('SET_NAME', `${admin.nombre} ${admin.apellido}`)
      commit('SET_GROUP', 'admin')
    }
    if (clienteInterno) {
      commit('SET_CLIENTE_INTERNO', {
        ...clienteInterno,
        name: `${clienteInterno.nombre} ${clienteInterno.apellido}`,
      })
      commit('SET_NAME', `${clienteInterno.nombre} ${clienteInterno.apellido}`)
      commit('SET_GROUP', 'clienteInterno')
    }
    commit('SET_AVATAR', '')
    commit('SET_LOADED', true)
  },

  changeDark ({ commit, state }) {
    commit('SET_DARK', !state.dark)
    setCookie(state.dark, 'mc_front_dark_mode')
  },
  async getReportes ({ commit, state }) {
    commit('SET_REPORTES', {})
    const { proveedor } = getCookie()
    const data = await genericRequest({
      url: getReportes,
      method: 'get',
      params: {
        id_proveed: proveedor.id_proveed,
      },
    })

    if (data?.length) {
      const dataObject = {}
      data.forEach((element) => {
        const key = Object.keys(element)[0]
        dataObject[key] = element[key]
      })

      commit('SET_REPORTES', dataObject)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
