<template>
  <v-row
    :justify="justify"
    align="center"
  >
    <v-col
      :class="`justify-${justify} ${justify!=='start'?'d-flex':''}`"
      class="py-0 m-width-220px"
    >
      <div
        v-if="label"
        class="pb-2 font-weight-medium"
      >
        {{ label }}
      </div>

      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        top
        :disabled="disabled"
        nudge-bottom="105"
        nudge-left="16"
      >
        <template #activator="{ on }">
          <v-tooltip
            bottom
            :disabled="!tooltip"
          >
            <template #activator="{ on:onTooltip, attrs:attrsTooltip }">
              <div
                v-bind="attrsTooltip"
                class="tooltipContainer"
                v-on="onTooltip"
              >
                <div
                  :style="swatchStyle"

                  v-on="on"
                />
              </div>
            </template>
            <span>{{ tooltip }}</span>
          </v-tooltip>
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker
              v-model="color"
              flat
            />
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              text
              class="text-none"
              @click="cancel"
            >
              Cancelar
            </v-btn>
            <v-btn
              class="text-none"
              color="success"
              text
              @click="acept"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'CustomInputColor',
    props: {
      label: {
        type: [String, Boolean],
        default: false,
      },
      justify: {
        type: [String],
        default: 'start',
      },
      value: {
        type: [String, Boolean],
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: [String, undefined],
        default: undefined,
      },
    },
    data () {
      return {
        color: '',
        menu: false,
      }
    },
    computed: {
      swatchStyle () {
        const { color, menu } = this
        return {
          backgroundColor: color,
          border: '1px solid #666666',
          cursor: 'pointer',
          height: '30px',
          width: '30px',
          borderRadius: menu ? '50%' : '4px',
          transition: 'border-radius 200ms ease-in-out',
        }
      },
    },
    watch: {
      value (value) {
        if (!value) {
          this.color = '#666666'
        } else {
          this.color = value
        }
      },
    },
    mounted () {
      if (!this.value) {
        this.color = '#666666'
      } else {
        this.color = this.value
      }
    },
    methods: {
      cancel () {
        this.color = this.value
        this.menu = false
      },
      acept () {
        this.$emit('changeCustomInput', this.color)
        this.menu = false
      },
    },
  }
</script>

<style>
.tooltipContainer{
  max-width: 30px;
}
</style>
