import { shortDate } from '@/util/helpers'
export const pedidosMapper = (items) => {
  return items.map((obj) => ({
    ...obj,
    calle: obj.CALLE || obj.calle || obj.idCALLEem,
    unit: obj.unirecep,
    items_number: '-',
    remitos_number: '-',
    fecha: shortDate(obj.fecha),
    estado: obj?.STATERECEP === 2 ? 'Entregado' : 'Pendiente de entrega',
    data: obj,

  }))
}
export const pedidoMapper = (items) => {
  return items.map((obj) => {
    const disponible = parseFloat(obj.disponible) > 0 ? parseFloat(obj.disponible) : 0

    const total = parseFloat(obj.cantidad) > 0 ? parseFloat(obj.cantidad) : 0
    const cantEnviada = parseFloat(obj.cantdesp) > 0 ? parseFloat(obj.cantdesp) : 0

    // Comento el disponibleWithMargin, para mandar solo lo que realmente queda disponible
    const disponibleWithMargin = Math.trunc(total * 1.25) - cantEnviada

    return {
      ...obj,
      cantReemplazo: obj.reemplazo?.length ? obj.reemplazo.length : 0,
      reemplazo: obj.reemplazo?.length
        ? obj.reemplazo.map((reemp) => {
          return { ...reemp, disponible: 0 }
        },
        ) : [],
      calle: obj.CALLE || obj.calle || obj.idCALLEem,
      zona: obj.sector,
      localidad: obj.localidad,
      provincia: obj.provincia,
      forecode: obj.xForece,
      id_articulo: obj.id_articulo,
      efecto: obj.efecto,
      total: total,
      totalTxt: obj.cantidad.replace('.', ','),
      disponible: disponible,
      disponibleOriginalWithoutMargin: disponible,
      disponibleOriginal: disponible,
      disponibleTxt: disponible > 0 ? obj.disponible.replace('.', ',') : 0,
      descripcion: obj.descripcion,
      isSelectable: disponible > 0,
    }
  })
}

export const pedidosHeader = [
  {
    text: 'N° Pedido',
    value: 'id_pedido',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '',
  },
  {
    text: 'Orden de entrega',
    value: 'idemcmc',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '',
  },
  // {
  //   text: 'Items',
  //   align: 'center',
  //   value: 'items_number',
  //   sortable: true,
  //   class: 'font-weight-medium greyTxt--text text-caption text-none',

  // },
  // {
  //   text: 'Remitos',
  //   align: 'center',
  //   value: 'cant_remitos',
  //   sortable: true,
  //   class: 'font-weight-medium greyTxt--text text-caption text-none',

  // },
  {
    text: 'Estado',
    value: 'estado',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: 'Lugar entrega',
    value: 'lugentrega_custom',
    sortable: false,
    align: 'start',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    width: '300px',
  },
  {
    text: 'Fecha entrega',
    value: 'fecha',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',

  },
  {
    text: '',
    align: 'end',
    value: 'actions',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
  },
]

export const pedidosClienteHeader = [
  {
    text: 'Suministro',
    value: 'suministro',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none px-0',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '95px',
  },
  {
    text: 'N° Pedido',
    value: 'id_pedido',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '',
  },
  {
    text: 'Orden de entrega',
    value: 'idemcmc',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  // {
  //   text: 'Items',
  //   align: 'center',
  //   value: 'items_number',
  //   sortable: true,
  //   class: 'font-weight-medium greyTxt--text text-caption text-none',

  // },
  {
    text: 'Remitos',
    align: 'center',
    value: 'cant_remitos',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',

  },
  {
    text: 'Estado',
    value: 'estado',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',
  },
  {
    text: 'Unidad',
    value: 'id_destino',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',

  },
  {
    text: 'Lugar entrega',
    value: 'lugentrega_custom',
    sortable: false,
    align: 'start',
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',
    width: '255px',
  },
  {
    text: 'Fecha entrega',
    value: 'fecha',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none pr-0',

  },
  {
    text: '',
    align: 'end',
    value: 'actions',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none px-0',
    width: '230px',
  },
]

export const pedidoHeaderDetalle = [

  {
    text: 'Zona',
    value: 'zona',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '150px',
  },
  {
    text: 'Localidad',
    align: 'start',
    sortable: false,
    value: 'localidad',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '150px',
  },
  {
    text: 'Provincia',
    align: 'start',
    sortable: false,
    value: 'provincia',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '150px',
  },
  {
    text: 'Forecode',
    value: 'forecode',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '40px',
  },
  {
    text: 'Efecto',
    value: 'efecto',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '150px',
  },
  {
    text: 'Total',
    value: 'total',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '100px',
  },
  {
    text: 'Remanente',
    value: 'disponible',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
    width: '190px',
  },
  { text: 'Reemplazos', value: 'data-table-expand', align: 'start' },
  {
    text: 'Descripción',
    value: 'descripcion',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },

]

export const reportePedidosMapper = (items) => {
  const maxWidthDescripcion = items.reduce((w, r) => Math.max(w, r.descripcion.length), 10)
  return {
    widths: [{ wch: 15 }, { wch: 15 }, { wch: maxWidthDescripcion }, { wch: 15 }, { wch: 15 }],
    headers: [
      [
        'ID proveedor',
        'ID artículo',
        'Descripción',
        'Cant. Solicitada',
        'Cant. Pendiente',
      ],
    ],
    items: items.map((item) => {
      return {
        id_proveed: item.id_proveed,
        id_articulo: item.id_articulo,
        descripcion: item.descripcion,
        cantidad_solicitada: parseFloat(item.cantidad_solicitada) > 0 ? parseFloat(item.cantidad_solicitada) : 0,
        cantidad_pendiente: parseFloat(item.cantidad_pendiente) > 0 ? parseFloat(item.cantidad_pendiente) : 0,

      }
    },
    ),
  }
}
