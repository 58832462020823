// Utilities
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/modules'
import { getClientes } from '@/routes/clientes'
import { clientesMapper, clientesHeader } from '@/mappers/clientesMapper'
import { getCookie } from '@/util/cookies'
const state = {
  items: [],
  headers: clientesHeader,
  headers_detalle: [
    {
      text: 'N° Pedido',
      align: 'start',
      sortable: false,
      value: 'order_number',
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      cellClass: 'font-weight-regular greyTxt--text',
      width: '16%',
    },
    {
      text: 'Tipo de pedido',
      align: 'start',
      sortable: false,
      value: 'order_type',
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      cellClass: 'font-weight-regular greyTxt--text',
      width: '16%',
    },
    {
      text: 'Items',
      align: 'start',
      sortable: false,
      value: 'items_number',
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      cellClass: 'font-weight-regular greyTxt--text',
      width: '8%',
    },
    {
      text: 'Remitos asociados',
      align: 'center',
      sortable: false,
      value: 'associated_remittances',
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      cellClass: 'font-weight-regular greyTxt--text text-center',
      width: '20%',
    },
    {
      text: 'Estado',
      align: 'start',
      value: 'status',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      width: '15%',
    },
    {
      text: '',
      align: 'end',
      value: 'actions',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      width: '25%',
    },
  ],
  loading: true,
  count: 0,
}

const mutations = make.mutations(state)

const actions = {

  async getData ({ commit, state }) {
    commit('SET_ITEMS', [])
    commit('SET_COUNT', 0)
    const { proveedor } = getCookie()
    const data = await genericRequest({
      url: getClientes,
      method: 'get',
      params: {
        id_proveedor: proveedor.id_proveed,
      },
    })
    if (data?.length) {
       const dataObject = {}
       data.forEach((element) => {
        const key = Object.keys(element)[0]
        dataObject[key] = element[key]
      })

      const dataMapped = clientesMapper([dataObject])
      commit('SET_COUNT', dataMapped.length)
      commit('SET_ITEMS', dataMapped)
    }

    commit('SET_LOADING', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
