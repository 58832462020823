export function leadingSlash (str) {
  return str.startsWith('/') ? str : '/' + str
}

export function trailingSlash (str) {
  return str.endsWith('/') ? str : str + '/'
}

export function capitalize (word) {
  return word[0].toUpperCase() + word.slice(1).toLowerCase()
}

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}

export function getVariablesBetweenString (word, firstCharacter, secondCharacter) {
  const out = []
word.replace(/({)\w+(})+/g, (e) => {
  out.push(e.replace('{', '').replace('}', ''))
  return e
})

  return out
}

export function replaceAll (str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace)
}

export const generateUrl = ({ href, id }) => {
  let url = replaceAll(href, ':slug', id)
  url = replaceAll(url, ':uuid', id)
  return url
}

function escapeRegExp (string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}
export const formatIdnumber = (number) => {
  if (number) {
    let firstNumber = number.toString().substr(0, 9)
    firstNumber = String(firstNumber).replace(/(.)(?=(\d{3})+$)/g, '$1.')
    return `${firstNumber}`
  }
}

export const getTokenData = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]))
  } catch (e) {
    return null
  }
}
export const months = (number) => {
  const months = {
    '01': 'Enero',
    '02': 'Febrero',
    '03': 'Marzo',
    '04': 'Abril',
    '05': 'Mayo',
    '06': 'Junio',
    '07': 'Julio',
    '08': 'Agosto',
    '09': 'Septiembre',
    10: 'Octubre',
    11: 'Noviembre',
    12: 'Diciembre',
  }
  if (number) { return months[number] }
  return months
}

export const shortDate = (date) => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  return `${day}/${month}/${year}`
}

export const textLong = (date) => {
  if (!date) return null
  const [year, month, day] = date.split('-')

  return `${day} de ${months(month)} de ${year}`
}

export const dateHelpers = { shortDate, textLong, months }

export const idnumber = (number) => {
  if (number) {
    let firstNumber = number.toString()
    firstNumber = String(firstNumber).replace(/(.)(?=(\d{3})+$)/g, '$1.')
    return `${firstNumber}`
  }
}

export const cuit = (number) => {
  if (number) {
    const firstNumber = number.substr(0, 2)
    const secondNumber = number.substr(2, 8)
    const thirdNumber = number.substr(10, 1)
    return `${firstNumber}-${secondNumber}-${thirdNumber}`
  }
}

export function toPrice (value) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value || 0)
}

export const toPathObject = (obj, is, value) => {
  if (typeof is === 'string') {
    return toPathObject(obj, is.split('.'), value)
  } else if (is.length === 1 && value !== undefined) {
    obj[is[0]] = value
    return obj[is[0]]
  } else if (is.length === 0) {
    return obj
  } else {
    return obj ? toPathObject(obj[is[0]], is.slice(1), value) : ''
  }
}

export const fomatters = { shortDate, textLong, months, idnumber, cuit }

export const urlToId = (url) => {
  if (url) {
    return replaceAll(url.toLowerCase(), '/', '-')
  }
  return url
}
