<template>
  <div>
    <div
      v-for="(file,key) in value"
      :key="`file-${key}`"
    >
      <div class="d-flex justify-space-between align-center py-3">
        <div
          v-if="label"
          class="text-subtitle-1 font-weight-negrita text-none "
        >
          {{ label }}
        </div>
        <!-- <a
        :href="file.url"
        target="_blank"
        class="segment mb-4 d-block text-decoration-none"
        @click="download(file.url, file.name)"
      /> -->
        <a
          :href="file.url"
          class="text-none blue--text text-decoration-none d-none d-md-block"
          target="_blank"
        >
          Ver en pantalla completa
          <v-icon
            color="blue"
            small
          >
            mdi-open-in-new
          </v-icon>

        </a>
      </div>
      <v-btn
        :href="file.url"
        dark
        color="blue"
        target="_blank"
        class="d-flex d-md-none"
        @click="download(file.url, 'poema.pdf',$event)"
      >
        Descargar
        <v-icon
          color="white"
          small
        >
          mdi-download
        </v-icon>
      </v-btn>
      <div class="d-none d-md-block">
        <object
          class="no-print mb1"
          :data="file.url"
          type="application/pdf"
          style="width:100%; height:800px;display: flex; justify-content: center; align-items: center; overflow: auto;"
        >
          <embed
            :src="file.url"

            type="application/pdf"
          >
        </object>
      </div>
    </div>
  </div>
</template>

<script>

  import { saveAs } from 'file-saver'

  export default {
    name: 'FileDownload',
    components: {

    },

    props: {
      value: {
        type: [Number, String, null, Array],
        default: function () {
          return ''
        },
      },
      label: {
        type: [String, null],
        default: '',
      },
    },

    methods: {

      download (url, name, event) {
        event.preventDefault()
        saveAs(url, name)
      },

    },
  }
</script>

<style scoped>

  </style>
