<template>
  <v-app id="myApp">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <custom-alert />
    <form-modal
      :form="formChangePassword"
      :data-source="{cuit}"
      :open.sync="modalChangePassword"
    />
  </v-app>
</template>

<script>
// Styles

  import { get, sync } from 'vuex-pathify'
  export default {
    name: 'App',
    metaInfo: {
      title: 'Abastecimiento',
      titleTemplate: '%s | Dashboard',
      htmlAttrs: { lang: 'es' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    data: () => ({

      formChangePassword: require('@/jsons/login/recover-session.json'),

    }),

    computed: {
        ...sync('app', ['modalChangePassword']),
        cuit: get('user/cuit'),
    },
  }
</script>
