<template>
  <v-tooltip
    bottom
    :disabled="!tooltip"
    :class="customClass"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        :color="color"
        class="text-none"
        v-bind="{...attributes,...attrs}"
        :class="customClass"
        :to="href"
        small
        :target="target"
        :loading="loading"
        :width="width"
        v-on="on"
        @click="$emit('handleClick')"
      >
        <v-icon
          v-if="icon"
          small
          :class="!!label?'mr-2':''"
        >
          mdi-{{ icon }}
        </v-icon>
        {{ label }}
      </v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
  export default {
    name: 'CustomBtn',
    props: {
      color: {
        type: String,
        default: '',
      },
      customClass: {
        type: String,
        default: '',
      },
      loading: {
        type: Boolean,
        default: false,
      },
      href: {
        type: [String, undefined],
        default: undefined,
      },
      target: {
        type: [String, undefined],
        default: undefined,
      },
      icon: {
        type: [String, Boolean, undefined],
        default: undefined,
      },
      label: {
        type: [String, undefined],
        default: undefined,
      },
      tooltip: {
        type: [String, undefined],
        default: undefined,
      },
      width: {
        type: [String, undefined],
        default: undefined,
      },
      attributes: {
        type: Object,
        default: function () {
          return { }
        },
      },
    },
  }
</script>

<style>

</style>
