<template>
  <VRow
    align="center"
    justify="start"
  >
    <VCol cols="auto">
      <div
        v-if="label"
        class="text-headline"
      >
        {{ label }}
      </div>
      <div class="text-subtitle-1">
        {{ value }}
      </div>
    </VCol>
    <VCol
      v-if="edit"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :icon="editIcon"
        :atributes="{icon:true,'x-small':true}"
        :tooltip="editTooltip"
        @handleClick="$emit('edit')"
      />
    </vcol>
    <VCol
      v-if="showHistory"
      cols="auto"
      class="px-1"
    >
      <custom-btn
        :icon="showIcon"
        :atributes="{icon:true,'x-small':true}"
        :tooltip="showTooltip"
        @handleClick="$emit('show-history')"
      />
    </vcol>
  </VRow>
</template>

<script>
  export default {
    name: 'CustomInputEditBrowse',
    props: {
      value: {
        type: [Number, String, null, Array],
        default: function () {
          return ''
        },
      },
      label: {
        type: [String, null],
        default: '',
      },
      editIcon: {
        type: [String, null],
        default: 'pencil',
      },
      editTooltip: {
        type: [String, null],
        default: 'Editar',
      },
      showTooltip: {
        type: [String, null],
        default: 'Ver histórico',
      },
      showIcon: {
        type: [String, null],
        default: 'history',
      },
      edit: {
        type: Boolean,
        default: false,
      },
      showHistory: {
        type: Boolean,
        default: false,
      },
    },
  }
</script>

<style>

</style>
