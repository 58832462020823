<template>
  <VMenu
    v-model="menu"
    :close-on-content-click="false"
    min-width="auto"
    left
    :disabled="readonly"
    transition="scale-transition"
  >
    <template #activator="{on, attrs}">
      <VTextField
        v-model="computedFecha"
        readonly
        class="mt-0"
        hide-details
        color="insti"
        :label="label"
        :clearable="!readonly"
        v-bind="attrs"
        :rules="rules"
        append-icon="mdi-calendar"
        v-on="on"
        @click:clear="data=null"
      />
    </template>
    <VDatePicker
      v-model="data"
      locale="es-AR"
      no-title
      :readonly="readonly"

      @input="change"
    />
  </VMenu>
</template>

<script>
  import { dateHelpers } from '@/util/helpers'

  export default {
    name: 'DatePicker',
    props: {
      rules: {

        type: Array,
        default: function () {
          return []
        },
      },
      value: {
        type: String,
        default: '',
      },
      format: {
        type: String,
        default: 'shortDate',
      },
      label: {
        type: String,
        default: 'Fecha',
      },

      readonly: {
        type: Boolean,
        default: false,
      },

    },
    data () {
      return { menu: false, data: '' }
    },
    computed: {

      computedFecha: {
        get () {
          return dateHelpers[this.format](this.data)
        },

        set (newName) {
          return newName
        },
      },

    },
    watch: {
      data (val) {
        this.$emit('changeCustomInput', val)
      },
      value (val) {
        this.data = val
      },
    },
    methods: {
      change () {
        this.menu = false
      },

    },
  }
</script>

<style>

</style>
