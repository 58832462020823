// Utilities
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/modules'
import { getPedidos, reportePedidos, clientePedidos, reportePedidosClientes } from '@/routes/pedidos'
import { pedidosMapper, pedidosHeader, pedidoHeaderDetalle, pedidoMapper, reportePedidosMapper, pedidosClienteHeader } from '@/mappers/pedidosMapper'
import { getCookie } from '@/util/cookies'

const state = {
  items: [],
  headers: pedidosHeader,
  headers_detalle_pedido: pedidoHeaderDetalle,
  headersCliente: pedidosClienteHeader,
  loading: true,
  count: 0,
  count_pedido_selected: 0,
  pedido_selected: [],
}

const mutations = make.mutations(state)

const actions = {
  async getDataCliente ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_COUNT', 0)
    commit('SET_ITEMS', [])
    const { resources: data } = await genericRequest({
      url: clientePedidos,
      method: 'get',
      params: query,
    })

    if (data) {
      const dataMapped = pedidosMapper(data)

      commit('SET_COUNT', dataMapped.length)
      commit('SET_ITEMS', dataMapped)
    }

    commit('SET_LOADING', false)
  },
  async getData ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_COUNT', 0)
    commit('SET_ITEMS', [])
    const { proveedor } = getCookie()
    const { resources: data } = await genericRequest({
      url: getPedidos,
      method: 'get',
      params: {
        id_proveed: proveedor.id_proveed,
        id_cliente: query.cliente_id,
      },
    })

    if (data) {
      const dataMapped = pedidosMapper(data)

      commit('SET_COUNT', dataMapped.length)
      commit('SET_ITEMS', dataMapped)
    }

    commit('SET_LOADING', false)
  },

  async reporte ({ commit, state }, query) {
    commit('SET_LOADING', true)
    const { proveedor } = getCookie()
    const data = await genericRequest({
      url: reportePedidos,
      method: 'get',
      params: {
        id_proveed: proveedor.id_proveed,
        ...query,
      },
    })

    commit('SET_LOADING', false)
    return reportePedidosMapper(data)
  },
  async reporteClientes ({ commit, state }, query) {
    commit('SET_LOADING', true)
    const data = await genericRequest({
      url: reportePedidosClientes,
      method: 'post',
      params: query,
    })

    commit('SET_LOADING', false)
    return reportePedidosMapper(data)
  },

  async getPedidoCliente ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_PEDIDO_SELECTED', [])
    commit('SET_COUNT_PEDIDO_SELECTED', 0)
    const { resources } = await genericRequest({
      url: `${clientePedidos}/${query.pedido}`,
      method: 'get',
      params: {
        IDEMCMC: query.pedido,
      },
    })

    if (resources) {
      const dataMapped = pedidoMapper(resources)

      commit('SET_COUNT_PEDIDO_SELECTED', dataMapped.length)
      commit('SET_PEDIDO_SELECTED', dataMapped)
    }

    commit('SET_LOADING', false)
  },

  async getPedido ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_PEDIDO_SELECTED', [])
    commit('SET_COUNT_PEDIDO_SELECTED', 0)
    const { proveedor } = getCookie()
    const { resources } = await genericRequest({
      url: `${getPedidos}/${query.pedido}`,
      method: 'get',
      params: {
        id_proveed: proveedor.id_proveed,
        IDEMCMC: query.pedido,
      },
    })

    if (resources) {
      const dataMapped = pedidoMapper(resources)

      commit('SET_COUNT_PEDIDO_SELECTED', dataMapped.length)
      commit('SET_PEDIDO_SELECTED', dataMapped)
    }

    commit('SET_LOADING', false)
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
