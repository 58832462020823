import Cookies from 'js-cookie'

const TokenKey = 'mc_session'

export function getCookie (key = TokenKey) {
  const token = Cookies.get(key)
  let data = null
  if (token) {
    data = JSON.parse(token)
  }
  if (key === 'mc_session') {
    return data?.token_type && data?.token ? { token_type: data.token_type, token: data.token, proveedor: data.proveedor, admin: data.admin, cliente_interno: data.cliente_interno, cuit: data.cuit } : false
  }

  return token
}

export function setCookie (token, key = TokenKey) {
  return Cookies.set(key, token)
}

export function removeCookie (key = TokenKey) {
  return Cookies.remove(key)
}
