<template>
  <VRow
    align="end"
    justify="start"
  >
    <template v-if="filtersSearch || filters">
      <VCol
        v-for="(_filter,key_filter) in filtersSearch"
        :key="key_filter"
        cols="12"
        :md="_filter.cols?_filter.cols+1:6"
        :lg="_filter.cols?_filter.cols:4"
        class=""
      >
        <search
          :disabled="loading"
          :placeholder="_filter.placeholder"
          @click="filter($event,_filter)"
          @change="filterChange($event,_filter)"
        />
      </VCol>
      <VCol
        v-for="(_filter,key_filter) in filters"
        :key="`filters_${key_filter}`"
        cols="12"
        :md="_filter.cols?_filter.cols+1:6"
        :lg="_filter.cols?_filter.cols:4"
      >
        <div
          v-if="_filter.title"
          class="text-subtitle-2"
        >
          {{ _filter.title }}
        </div>
        <VRow>
          <VCol
            v-for="(_filterField,keyFilterField) in _filter.fields"
            :key="`${key_filter}-${keyFilterField}`"
            class="d-flex  align-end"
            cols="12"
            :md="_filterField.cols?_filterField.cols:'12'"
          >
            <component
              :is="_filterField.type"
              class="my-0 py-0"
              hide-details
              v-bind="_filterField.attr"
              :disabled="loading"
              :label="_filterField.placeholder"
              @changeCustomInput="filter($event,_filterField)"
              @change="filter($event,_filterField)"
            />
          </VCol>
        </VRow>
      </VCol>
      <VCol
        v-for="(_action,key_action) in actionsBtn"
        :key="`actionBtn_${key_action}`"
        cols="12"
        :md="_action.cols?_action.cols+1:6"
        :lg="_action.cols?_action.cols:4"
        class="d-flex justify-end align-center mb-4 "
      >
        <app-btn
          class="mx-2"
          :color="_action.color"
          dark
          @click="$emit(_action.action)"
        >
          {{ _action.title }}
        </app-btn>
      </VCol>
    </template>
    <VCol
      cols="12"
      class=""
    >
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        class="elevation-0 ml-2"
        locale="es-AR"
        :loading="loading"
        :height="height"
        :options="options"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :show-select="showSelect"
        :item-key="itemKey"
        :search="search"
        selectable-key="isSelectable"
        loading-text="Cargando... Por favor espere"
        no-data-text="La tabla se encuentra vacía"
        :expanded.sync="expanded"
        :show-expand="showExpand"
        :footer-props="{
          itemsPerPageText: 'Pedidos por página',
          itemsPerPageOptions: [10,20, 50, 100, 150, 200, 250],
        }"
        @toggle-select-all="changeAllItemSelected"
        @item-selected="changeItemSelected"
      >
        <template
          v-if="isSearch"
          #top
        >
          <v-text-field
            v-model="search"
            label="Buscar"
            class="mx-4"
            @change="$emit('search')"
          />
        </template>
        <template #[`item.data-table-expand`]="{ item, expand, isExpanded }">
          <v-btn
            v-if="item.cantReemplazo"
            text
            class="no-background"
            @click="expand(!isExpanded)"
          >
            {{ item.cantReemplazo }}
            <v-icon>
              mdi-chevron-{{ isExpanded?'up':'down' }}
            </v-icon>
          </v-btn>
        </template>

        <template #[`expanded-item`]="{ item }">
          <template v-if="item.cantReemplazo">
            <td
              :colspan="headers.length"
              class="px-0"
            >
              <v-data-table
                class="inner-table"
                hide-default-footer
                :items="item.reemplazo "
                :headers="[
                  {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'label',
                    class: 'font-weight-medium greyTxt--text text-caption text-none',
                    cellClass: 'font-weight-regular greyTxt--text',
                    align: 'end',
                    width: '300px'
                  },
                  {
                    text: '',
                    value: 'descreemp',
                    sortable: false,
                    class: 'font-weight-medium greyTxt--text text-caption text-none',
                    cellClass: 'font-weight-regular greyTxt--text',
                    align: 'start',
                    width: '545px'
                  },
                  {
                    text: '',
                    value: 'total',
                    sortable: false,
                    class: 'font-weight-medium greyTxt--text text-caption text-none',
                    cellClass: 'font-weight-regular greyTxt--text',
                    align: 'start',
                  },
                ]"
              >
                <template #[`item.label`]="{ }">
                  <div class="text-subtitle-2 reemplazoTxt--text font-weight-regular">
                    Reemplazo
                  </div>
                </template>
                <template #[`item.efecto`]="{ }">
                  <div>
                    {{ item.descreemp }}
                  </div>
                </template>
                <template #[`item.total`]="{ item:itemInner }">
                  <v-text-field
                    v-if="checkItemSelected(item.id_articulo)"
                    v-model.number="itemInner.disponible"
                    prepend-inner-icon="mdi-minus"
                    outlined
                    class="field-number"
                    hide-details
                    append-icon="mdi-plus"
                    @click:append="handleDisponibleInnerChange({item,itemInner},itemInner.disponible+=1)"
                    @click:prepend-inner="handleDisponibleInnerChange({item,itemInner},itemInner.disponible-=1)"
                    @change="handleDisponibleInnerChange({item,itemInner},$event)"
                  />
                </template>
              </v-data-table>
            </td>
          </template>
        </template>
        <!-- Estado -->
        <template #[`item.estado`]="{ item }">
          <v-chip
            :color="item.estado | colorStatus"
            dark
          >
            <div class="font-weight-regular text-caption">
              {{ item.estado }}
            </div>
          </v-chip>
        </template>
        <template #[`item.total`]="{ item }">
          <span>
            {{ item.totalTxt }}
          </span>
        </template>
        <template #[`item.disponible`]="{ item }">
          <v-text-field
            v-if="checkItemSelected(item.id_articulo)"
            v-model.number="item.disponible"
            class="field-number"
            :max="item.disponibleOriginal"
            prepend-inner-icon="mdi-minus"
            append-icon="mdi-plus"
            outlined
            hide-details
            @click:append="handleDisponibleChange(item,item.disponible+=1)"
            @click:prepend-inner="handleDisponibleChange(item,item.disponible-=1)"
            @change="handleDisponibleChange(item)"
          />
          <span v-else>
            {{ item.disponibleTxt }}
          </span>
        </template>
        <template #[`item.lugentrega_custom`]="{ item }">
          <div
            v-if="item.lugentrega"
            class="font-weight-regular text-subtitle-2"
          >
            {{ item.lugentrega }}
          </div>
          <div>
            <span
              v-if="item.localidad"
              class="font-weight-regular text-subtitle-2"
            >
              {{ item.localidad }}
            </span>

            <span
              v-if="item.partido"
              class="font-weight-regular text-subtitle-2"
            >
              , {{ item.partido }}
            </span>
            <span
              v-if="item.provincia"
              class="font-weight-regular text-subtitle-2"
            >
              , {{ item.provincia }}
            </span>
          </div>
        </template>
        <!-- Botones -->
        <template #[`item.actions`]="{ item }">
          <template v-for="action in actions">
            <custom-btn
              v-if="checkActionVisible(action,item)"
              :key="action.title"
              custom-class="text-none font-weight-regular text-caption mx-1 my-1 my-lg-0"
              :color="action.color"
              :icon="action.icon"
              :label="action.title"
              :attributes="action.attr?action.attr:{}"
              dark
              :tooltip="action.tooltip"
              @handleClick="action.emit?$emit(action.emit, item):false"
            />
          </template>
        </template>
      </v-data-table>
    </VCol>
  </VRow>
</template>

<script>
  import { colorStatus } from '@/mappers/status'
  import { get } from 'vuex-pathify'
  import filters from '@/mixins/filters'
  export default {
    name: 'GenericTable',
    filters: {
      colorStatus,
    },
    mixins: [filters],
    props: {
      dataTable: {
        type: Object,
        default: function () {
          return {}
        },
      },
      headers: {
        required: true,
        type: Array,
        default: function () {
          return []
        },
      },
      sortBy: {
        type: Array,
        default: function () {
          return ['idemcmc']
        },
      },
      sortDesc: {
        type: Array,
        default: function () {
          return [true]
        },
      },
      isSearch: {
        type: Boolean,
        default: false,
      },
      loading: {
        type: Boolean,
        default: false,
      },
      isRemito: {
        type: Boolean,
        default: false,
      },
      showExpand: {
        type: Boolean,
        default: false,
      },
      itemKey: {
        type: String,
        default: 'id',
      },
      showSelect: {
        type: Boolean,
        default: false,
      },
      height: { type: [String], default: '100%' },
      options: {
        type: Object,
        default: function () {
          return {
            page: 1,
            itemsPerPage: 10,
          }
        },
      },
      items: {
        type: Array,
        default: function () {
          return []
        },
      },
      actions: {
        type: Array,
        default: function () {
          return []
        },
      },
    },

    data: () => ({
      search: '',
      selected: [],
      ids: [],
      expanded: [],
      singleExpand: false,
      filtersValue: {},
    }),
    computed: {
      loading_pedidos: get('pedidos/loading'),
      filtersSearch () {
        return this.dataTable?.search?.length ? this.dataTable.search : []
      },
      filters () {
        return this.dataTable?.filters?.length ? this.dataTable.filters : []
      },
      actionsBtn () {
        return this.dataTable?.actionsBtn?.length ? this.dataTable.actionsBtn : []
      },
    },
    watch: {
      loading_pedidos (value) {
        if (value && this.isRemito) {
          this.selected = []
          this.ids = []
        }
      },
    },

    methods: {
      changeAllItemSelected (data) {
        this.selected = []
        this.ids = []
        if (data.value) {
          this.selected = this.items
          this.ids = this.items.map(e => e[this.itemKey])
        }
        this.$emit('changeSelected', this.ids)
      },
      filterChange (value, params) {
        delete this.filtersValue[params.param]
        if (value) {
          this.filtersValue[params.param] = value
        }
      },
      filter (value, params) {
        this.filtersValue[params.param] = value
        const query = this.handleFilter(this.filtersValue)
        this.selected = []
        this.ids = []
        this.$emit('changeSelected', this.ids)
        this.$emit('search', query)
      },
      filterOnlyCapsText (value, search) {
        return value != null &&
          search != null &&
          typeof value === 'string' && (
            value.toString().toLocaleUpperCase().indexOf(search) !== -1 || value.toString().toLocaleLowerCase().indexOf(search) !== -1)
      },
      checkActionVisible (action, item) {
        const response = !action.hidden && (action.emit !== 'delRemito' || item.deletable)

        let conditions = true
        if (action.condition?.length) {
          action.condition.forEach(e => {
            if (item[e.field] !== e.value) {
              conditions = false
            }
          })
        }

        return response && conditions
      },
      checkItemSelected (id) {
        return this.ids.includes(id)
      },
      changeItemSelected (data) {
        const indexItem = this.ids.findIndex(e => {
          return e === data.item[this.itemKey]
        })

        data.item.disponible = data.item.disponibleOriginalWithoutMargin
        if (data.value && (data.item.disponible > 0 || !this.isRemito)) {
          if (indexItem === -1) {
            this.ids.push(data.item[this.itemKey])
          }
        } else {
          this.ids.splice(indexItem, 1)
        }

        this.$emit('changeSelected', this.ids)
      },
      handleDisponibleChange (item, value = null) {
        if (value) {
          item.disponible = value
        }
        if (item.disponible < 0) {
          item.disponible = 0
        }
        if (item.disponible > item.disponibleOriginal) {
          item.disponible = item.disponibleOriginal
        }

        item.reemplazo.forEach(itemInner => {
          const max = this.calcItemMax({ item, itemInner })
          if (itemInner.disponible > max) {
            itemInner.disponible = max
          }
        })
      },

      handleDisponibleInnerChange ({ item, itemInner }, value = null) {
        if (value) {
          itemInner.disponible = value
        }
        if (itemInner.disponible < 0) {
          itemInner.disponible = 0
        }
        if (itemInner.disponible > this.calcItemMax({ item, itemInner })) {
          itemInner.disponible = this.calcItemMax({ item, itemInner })
        }
      },
      calcItemMax ({ item, itemInner }) {
        let sumUsed = 0
        item.reemplazo.forEach(element => {
          if (element.id_artreemp !== itemInner.id_artreemp) {
            sumUsed += element.disponible
          }
        })

        const used = item.disponible + sumUsed

        const tot = item.disponibleOriginal - used

        return tot >= 0 ? tot : 0
      },
    },

  }
</script>

<style lang="sass">
.field-number
  width:155px!important
  .v-input__slot
     min-height: 35px!important
  .v-input__append-inner
     margin-top:7px!important
  .v-input__prepend-inner
     margin-top:7px!important
.inner-table
  th
    height: 7px!important
</style>
