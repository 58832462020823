// Imports
import Vue from 'vue'
import Router from 'vue-router'
import { layout, route } from '@/util/routes'

import store from '@/store'
import { removeCookie, getCookie } from '@/util/cookies'
import getPageTitle from '@/util/get-page-title'

const hasToken = getCookie()
if (hasToken) {
  store.dispatch('user/getUserInfo')
}

const momdulesListConst = [
  layout('Default', [
    route('Redirect', null, '/redirect/:path(.*)'),
    route('dashboard/Index', null, '/', {
      drawer: true,
      title: 'Dashboard',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
      darkProfile: true,
    }),

    route('UserProfile', null, '/profile', {
      accountMenu: false,
      title: 'Perfil',
      loginRequired: true,
    }),
  ]),
  layout('listado', [
    route('Clientes', null, '/clientes', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('Pedidos', null, '/pedidos/:cliente_id/:cliente_nombre?', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('Pedido', null, '/pedido/:id/:cliente_nombre?/:id_pedido?', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('Remitos', null, '/remitos/:id?/:id_pedido?/:cliente_nombre?', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('Remito', null, '/remito/:id/:nro_orden?/:id_pedido?', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('Usuarios', null, '/usuarios', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('altaUsuarios', null, '/usuarios/alta', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
    route('ClientePedidos', null, '/cliente/:cliente_id/:id_destino?', {
      drawer: true,
      title: 'GenericTable',
      icon: 'mdi-view-dashboard',
      loginRequired: true,
    }),
  ]),
  {
    path: '/ingresar',
    component: () => import('@/views/login/index'),
  },
]

const whiteList = ['/ingresar', '/auth-redirect']

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    ...momdulesListConst,
    ...[
      {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('@/views/error-page/404'),
      },
    ],
  ],
})

router.beforeEach(async (to, from, next) => {
  // set page title
  store.commit('app/SET_LOADING', false)
  document.title = getPageTitle(to.meta.title)
  const hasToken = getCookie()

  if (hasToken) {
    if (to.path === '/logout') {
      store.commit('app/SET_LOADING', true)
      // await genericRequest({ url: 'logout', method: 'POST' })
      await removeCookie()

      window.localStorage.clear()
      store.commit('app/SET_LOADING', false)
      next({ path: '/ingresar' })
    } else if (to.path === '/ingresar') {
      // if is logged in, redirect to the home page
      next('/')
    } else {
      next()
    }
  } else {
    /* has no token */
    if (to.path === '/logout') {
      next('/ingresar')
    } else if (whiteList.indexOf(to.path) !== -1 || !to.meta.loginRequired) {
      // in the free login whitelist, go directly
      next()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/ingresar?redirect=${to.path}`)
    }
  }
})
export default router
