<template>
  <VSnackbar
    v-model="alert"
    right
    :timeout="alertTxt.timeout"
    top
  >
    <VAlert
      :type="alertTxt.type"
      v-html="alertTxt.txt"
    />
  </VSnackbar>
</template>

<script>
  import { get, sync } from 'vuex-pathify'
  export default {
    name: 'CustomAlert',

    components: {},
    computed: {
        ...sync('app', ['alert']),
        ...get('app', ['alertTxt']),
    },
  }
</script>

<style>

</style>
