import { shortDate } from '@/util/helpers'

export const remitosMapper = (items) => {
  return items.map((remito) => {
    const archivosCliente = remito.archivos.filter(e => e.subido_por === 'cliente')
    const archivosProveedor = remito.archivos.filter(e => e.subido_por === 'proveedor')
    return {
      ...remito,
      archivos: remito.archivos.filter(e => e.url),
      estado: staterecepMap(remito?.staterecep),
      deletable: deletableMap(remito?.staterecep),
      detalles: remito?.detalles
        ? Object.values(remito.detalles).map((obj) => ({
          ...obj,
          ...remito,
          calle: remito.CALLE || remito.calle || remito.idCALLEem,
          cantdesp: obj.cantdesp.replace('.', ','),
          cantped: obj.cantped.replace('.', ','),
          descrip: obj.id_artreemp !== obj.id_articulo ? `${obj.descreemp} (Reemplazo de ${obj.descrip})` : obj.descrip,
        }))
        : [],
      calle: remito.CALLE || remito.calle || remito.idCALLEem,
      lugar_entrega: remito.lugarentrega || remito.lugarentraga,
      fecha_entrega: shortDate(remito.cmpfentrega),
      fecha_estimada_entrega: shortDate(remito.cmpfestima),
      fecha_emision: shortDate(remito.cmpfemision),
      archivos_cliente: archivosCliente,
      archivos_proveedor: archivosProveedor,
      archivos_cliente_pendiente: !!(archivosProveedor?.length && !archivosCliente?.length),
    }
  },

  )
}

export const staterecepMap = (staterecep) => {
  if (staterecep === 1) {
    return 'Pendiente de entrega'
  }
  if (staterecep === 2) {
    return 'Entregado'
  }
  return ''
}

export const deletableMap = (staterecep) => {
  if (staterecep === 1) {
    return true
  }

  return false
}

export const remitosHeader = [
  {
    text: 'N° Remito',
    align: 'start',
    sortable: true,
    value: 'numero_remito',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'N° Pedido',
    value: 'id_pedido',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Orden de entrega',
    value: 'idemcmc',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },

  {
    text: 'Unidad',
    value: 'unirecep',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Fecha estimada',
    value: 'fecha_estimada_entrega',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Estado',
    value: 'estado',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end',
  },
]

export const remitosDashboardHeader = [
  {
    text: 'N° Remito',
    align: 'start',
    sortable: true,
    value: 'numero_remito',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'N° Pedido',
    value: 'id_pedido',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },

  {
    text: 'Unidad',
    value: 'unirecep',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Fecha estimada',
    value: 'fecha_estimada_entrega',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Estado',
    value: 'estado',
    sortable: true,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
    align: 'end',
  },
]

export const remitoHeaderDetalle = [
  {
    text: 'Unidad',
    value: 'unirecep',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'ID artículo',
    value: 'id_articulo',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Lugar entrega',
    value: 'lugar_entrega',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Localidad',
    align: 'start',
    sortable: false,
    value: 'localidad',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Provincia',
    align: 'start',
    sortable: false,
    value: 'provincia',
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Descripción',
    value: 'descrip',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
  {
    text: 'Cantidad enviada',
    value: 'cantdesp',
    sortable: false,
    class: 'font-weight-medium greyTxt--text text-caption text-none',
    cellClass: 'font-weight-regular greyTxt--text',
  },
]

export const sendRemitoHeaderMapper = (data) => {
  const { proveedor, items, razonSocial, fechaEntrega } = data
  const dataItem = items[0]
  return {
    id_proveed: proveedor.id_proveed,
    id_empresa: proveedor.id_empresa,
    id_sucursal: proveedor.id_sucursal,
    raz_soc: razonSocial,
    id_cliente: dataItem.id_cliente,
    idemcmc: dataItem.idemcmc,
    estcomp: '0',
    ptovtaprov: '0002',
    id_ptoventa: '0002',
    id_destino: dataItem.id_destino,
    lugentrega: dataItem.lugentrega,
    lugarentraga: dataItem.lugentrega,
    unirecep: dataItem.unirecep,
    sector: dataItem.sector,
    calle: dataItem.calle,
    localidad: dataItem.localidad,
    partido: dataItem.partido,
    provincia: dataItem.provincia,
    acueconc: dataItem.acueconc,
    cmpfentrega: dataItem.fecha,
    cmpfestima: fechaEntrega,
    cmpfrecep: '0000-00-00',
    id_pedido: dataItem.id_pedido,
    staterecep: '',
    namepict: '',
  }
}

export const sendDetalleRemitoMapper = ({ items }, idcontrol) => {
  return items.map((obj) => ({
    id_empresa: obj.id_empresa,
    id_sucursal: obj.id_sucursal,
    id_control: idcontrol,
    id_articulo: obj.id_articulo,
    id_pedido: obj.id_pedido,
    descrip: obj.descripcion,
    idemcmc: obj.idemcmc,
    descreemp: obj.descreemp ? obj.descreemp : obj.descripcion,
    id_artreemp: obj.id_artreemp ? obj.id_artreemp : obj.id_articulo,
    cantped: obj.total,
    cantdesp: obj.disponible,
    cantrec: 0,
    stateproc: '0',
    fecproc: '0000-00-00',
  }))
}

export const remitoMapper = (remito) => {
  return {
    ...remito,
    archivos: remito.archivos.filter(e => e.url),
    estado: remito?.staterecep === 2 ? 'Entregado' : 'Pendiente de entrega',
    fecha_entrega: shortDate(remito.cmpfentrega),
    fecha_estimada_entrega: shortDate(remito.cmpfestima),
    fecha_emision: shortDate(remito.cmpfemision),
    lugar_entrega: remito.lugarentrega || remito.lugarentraga,
    calle: remito.CALLE || remito.calle || remito.idCALLEem,
    detalles: remito?.detalles
      ? Object.values(remito.detalles).map((obj) => ({
        ...obj,
        ...remito,
        calle: remito.CALLE || remito.calle || remito.idCALLEem,
        cantdesp: obj.cantdesp.replace('.', ','),
        cantped: obj.cantped.replace('.', ','),
        lugar_entrega: remito.lugarentrega || remito.lugarentraga,
        descrip: obj.id_artreemp !== obj.id_articulo ? `${obj.descreemp} (Reemplazo de ${obj.descrip})` : obj.descrip,
      }))
      : [],
    archivos_cliente: remito.archivos.filter(e => e.subido_por === 'cliente'),
    archivos_proveedor: remito.archivos.filter(e => e.subido_por === 'proveedor'),
  }
}
