/* eslint-disable no-tabs */

export default {
	methods: {
		handleFilter (filters) {
			// this.$router.push({ path: this.$route.path, query })
			return filters
		},
	},
}
