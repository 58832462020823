import store from '@/store'
import router from '@/router'
import axios from 'axios'

import { getCookie, removeCookie } from '@/util/cookies'

axios.defaults.baseURL = process.env.VUE_APP_MOCK_API // url = base url + request url
// create an axios instance
const service = axios.create({
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
})

// request interceptor
service.interceptors.request.use(
  (config) => {
    const token = getCookie()
    if (token) {
      config.headers.Authorization = `${token.token_type} ${token.token}`
    }

    return config
  },
  (error) => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  },
)

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data
    const successMSg = res?.message
    if (successMSg) {
      store.commit('app/SET_ALERT_TXT', {
        timeout: 3700,
        type: 'success',
        txt: successMSg || '¡Éxito!',
      })
      store.commit('app/SET_ALERT', true)
    }

    return res
  },
  async (error) => {
    const errorStatus = error?.response?.status
    console.log('err' + error) // for debug
    console.log('status ' + errorStatus) // for debug
    if (errorStatus === 401) {
      await removeCookie(false)
      window.localStorage.clear()
      router.push({ path: '/ingresar' })
    }
    const errorMSg = error?.response?.data?.error?.message
    if (errorMSg) {
      store.commit('app/SET_ALERT_TXT', {
        timeout: 3700,
        type: 'error',
        txt: errorMSg || 'Error',
      })
      store.commit('app/SET_ALERT', true)
    }
    return Promise.reject(error)
  },
)

export default service
