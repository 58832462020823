<template>
  <v-row>
    <v-col
      cols="12"
      class="mt-2"
      :class="field.style?field.style.class:''"
    >
      <div
        v-if="label && label!=='' && String(label)!=='true'"
        class="text-subtitle-2 font-weight-negrita text-none"
        :class="{'error--text':checkFieldError}"
      >
        {{ label }}
      </div>

      <div
        v-if="description && description!=='' && String(description)!=='true'"
        class="text-overline text-none pt-1 pb-2"
        :class="{'error--text':checkFieldError}"
      >
        {{ description }}
      </div>
      <VueFileAgent
        :ref="ref"
        v-model="fileRecords"
        :accept="filesAccept"
        :deletable="true"
        :disabled="disabled"
        :editable="false"
        width="20px"
        :error-text="{
          type: 'Archivo no válido',
          size: `El archivo no debe superar los ${maxSize}`,
        }"
        :help-text="title"
        :linkable="true"
        :max-files="Number(maxFiles)"
        :max-size="maxSize"
        :meta="meta"
        :multiple="String(multipleFiles)==='true'"
        :theme="theme"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
        @select="filesSelected($event)"
      />

      <div
        v-if="checkFieldError"
        class="text-overline text-none pt-1 pb-2 error--text"
      >
        Completar
      </div>
    </v-col>
  </v-row>
</template>

<script>
  import 'vue-file-agent/dist/vue-file-agent.css'
  export default {
    name: 'UploadFile',
    components: {},
    props: {
      multipleFiles: {
        type: [Boolean, String],
        default: false,
      },
      disabled: {
        type: [Boolean, String],
        default: false,
      },
      filesAccept: {
        type: String,
        default: '',
      },

      field: {
        type: Object,
        default: function () {
          return {}
        },
      },
      title: {
        type: [Boolean, String],
        default: false,
      },
      meta: {
        type: [Boolean],
        default: false,
      },
      label: {
        type: [Boolean, String],
        default: false,
      },
      description: {
        type: [Boolean, String],
        default: false,
      },
      theme: {
        type: String,
        default: 'default',
      },
      emitSubmit: {
        type: [Boolean, String],
        default: false,
      },
      statusModal: {
        type: [Boolean, String],
        default: false,
      },
      maxSize: {
        type: String,
        default: '2MB',
      },

      maxFiles: {
        type: [Number, String],
        default: 1,
      },
      value: {
        type: [String, Number, Boolean, Object, Array],
        default: '',
      },
    },

    data () {
      return {
        fileRecords: [],
        fileRecordsForUpload: [],
      }
    },

    computed: {
      checkFieldError () {
        if (this.field?.required === 'true' && !this.fileRecordsForUpload?.length && this.emitSubmit) {
          return true
        }
        return false
      },
      ref () {
        return `files_${this.folder}`
      },
    },
    watch: {
      value (val) {
        if (val && (typeof val === 'string' || val instanceof String)) {
          const files = []
          files.push({
            url: val,
            type: 'image',
            name: 'profile',
            size: 1,
          })
          this.fileRecords = files
        }
      },
      statusModal (val) {
        this.fileRecords = []
        this.fileRecordsForUpload = []
      },
    },

    mounted () {
      this.$emit('ready')

      this.fileRecords = []
    },
    methods: {
      filesSelected (fileRecordsNewlySelected) {
        var validFileRecords = fileRecordsNewlySelected.filter(
          (fileRecord) => !fileRecord.error,
        )
        if (String(this.multipleFiles) === 'true' && Number(this.maxFiles) > 1) {
          this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
        } else {
          this.fileRecordsForUpload = validFileRecords
        }

        if (this.fileRecordsForUpload.length > 0) {
          this.$emit('upload-file', {
            element: this.$refs[this.ref],
            fileRecordsForUpload: this.fileRecordsForUpload,
          })
        }
      },

      onBeforeDelete (fileRecord, refs) {
        this.$refs[this.ref].deleteFileRecord(fileRecord)
      },

      fileDeleted (fileRecord) {
        var i = this.fileRecordsForUpload.indexOf(fileRecord)

        if (i !== -1) {
          this.fileRecordsForUpload.splice(i, 1)

          this.$emit('upload-file', {
            element: this.$refs[this.ref],
            fileRecordsForUpload: this.fileRecordsForUpload,
            folder: this.folder,
            bucket: this.bucket,
          })
        } else {
          this.deleteUploadedFile(fileRecord)
        }
      },

      deleteUploadedFile (fileRecord, refs) {
        this.$emit('deleted', fileRecord)
      },
    },
  }
</script>
<style>
.vue-file-agent .file-preview-wrapper .file-preview .file-delete {
  color: #ea2626 !important;
  z-index:10;
}

</style>
