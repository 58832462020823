// Utilities
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/modules'
import { getRemitos, createRemito, createRemitoItems, getRemito, sendPhoto } from '@/routes/remitos'
import { remitosMapper, remitosHeader, sendRemitoHeaderMapper, sendDetalleRemitoMapper, remitoMapper, remitoHeaderDetalle, remitosDashboardHeader } from '@/mappers/remitosMapper'
import { getCookie } from '@/util/cookies'
const state = {
  items: [],
  headers: remitosHeader,
  headers_detalle_remito: remitoHeaderDetalle,
  dashboardHeader: remitosDashboardHeader,
  remito_selected: { detalles: [] },
  count: 0,
  loading: true,
}

const mutations = make.mutations(state)

const actions = {

  async getData ({ commit, state, rootGetters }, data) {
    const { proveedor } = getCookie()
    commit('SET_ITEMS', [])
    commit('SET_COUNT', 0)
    const params = {
      id_proveed: proveedor?.id_proveed || data.id_proveed,
    }
    if (data?.idemcmc) {
      params.idemcmc = data.idemcmc
    }
    if (data === 'home') {
      params.is_home = 1
    }
    const { resources } = await genericRequest({
      url: getRemitos,
      method: 'get',
      params,
    })
    if (resources) {
      const dataMapped = remitosMapper(resources)
      commit('SET_COUNT', dataMapped.length)
      commit('SET_ITEMS', dataMapped)
    }

    commit('SET_LOADING', false)
  },

  async createRemito ({ commit, state, rootGetters }, remito) {
    const { resource } = await genericRequest({
      url: createRemito,
      method: 'post',
      params: sendRemitoHeaderMapper(remito),
    })

    await genericRequest({
      url: createRemitoItems,
      method: 'post',
      params: { detalles: { items: sendDetalleRemitoMapper(remito, resource.id_control) } },
    })
  },
  async getRemito ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_REMITO_SELECTED', {})
    const { proveedor } = getCookie()
    const { resource } = await genericRequest({
      url: `${getRemito}/${query.id_control}`,
      method: 'get',
      params: {
        id_proveed: proveedor?.id_proveed || query.id_proveed,
      },
    })

    if (resource) {
      const dataMapped = remitoMapper(resource)

      commit('SET_REMITO_SELECTED', dataMapped)
    }

    commit('SET_LOADING', false)
  },
  async sendPhoto ({ commit, state, rootState }, remito) {
    const { group } = rootState.user
    await genericRequest({
      url: sendPhoto[group],
      method: 'post',
      params: remito,
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
