const { camelCase } = require('lodash')

const statusColor = {
    enDistribucion: 'vividBlue',
    pendienteDeEntrega: 'vividBlue',
    pendienteDeRemito: 'pureOrange',
    completo: 'success',
    entregado: 'success',
}

export const colorStatus = (status) => {
    return statusColor[camelCase(status)]
}
