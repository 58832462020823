// Utilities
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/modules'
import { getAdministradores, clearPassword, updateUser } from '@/routes/usuarios'
import { usuariosAltaHeaders } from '@/mappers/usuariosMapper'

const state = {
  items: [],
  headers: usuariosAltaHeaders,
  loading: true,
  count: 0,
}

const mutations = make.mutations(state)

const actions = {

  async getData ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_COUNT', 0)
    commit('SET_ITEMS', [])

    const { resources: data } = await genericRequest({
      url: getAdministradores,
      method: 'get',
    })
    if (data) {
      state.items = data
      commit('SET_COUNT', data.length)
    }
    commit('SET_LOADING', false)
  },

  async clearPass ({ commit, state }, query) {
    commit('SET_LOADING', true)
    await genericRequest({
      url: clearPassword,
      method: 'post',
      data: query,
    })
    commit('SET_LOADING', false)
  },

  async updateUser ({ commit, state }, query) {
    commit('SET_LOADING', true)
    await genericRequest({
      url: updateUser,
      method: 'post',
      data: query.item,
    })
    commit('SET_LOADING', false)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
