<template>
  <v-tooltip
    bottom
    :disabled="!tooltip || !isOver"
  >
    <template
      v-if="vuetifyField"
      #activator="{ on, attrs }"
    >
      <Component
        :is="vuetifyField.element"
        :id="field.name"
        :field="field"
        :title="field.title"
        :readonly="readonly || attributes.readonly"
        :outlined="outlined"
        :solo="solo"
        :dense="dense"
        :emit-submit="emitSubmit"
        :format="field.format"
        :description="description"
        :model="model"
        :value="inputValue"
        :autocomplete="field.autocomplete ? field.autocomplete : 'off'"
        class=""
        :status-modal="statusModal"
        :tooltip="tooltip"
        :hint="(!inputValue || inputValue === '') && !!field.hint ? field.hint : null"
        :label="label"
        :prepend-inner-icon="
          field.style && !!field.style.prependInnerIcon
            ? field.style.prependInnerIcon
            : null
        "
        :append-icon="
          field.type === 'password'
            ? !showPass
              ? 'mdi-eye'
              : 'mdi-eye-off'
            : field.style && !!field.style.appendIcon
              ? field.style.appendIcon
              : vuetifyField.element==='v-select'|| vuetifyField.element==='v-autocomplete'?'$dropdown':null
        "
        :counter="field.type | toCounter"
        :maxlength="field.type | toCounter"
        :type="
          field.type === 'password'
            ? showPass
              ? 'text'
              : 'password'
            : field.type | toType
        "
        :placeholder="field.placeholder ? field.placeholder : ''"
        :rules="rules"
        :single-line="false"
        :disabled="loading || attributes.disabled"
        :autofocus="field.focus"
        v-bind="{ ...attributes, ...attrs }"
        :items="items"
        :loading="loading"
        :item-text="itemText"
        :item-value="itemValue"
        :hide-details="false"
        :data-parent-model="dataParentModel"
        :no-data-text="nameListEmptyField"
        :multiple="field.multiple&& String(field.multiple) === 'true'"
        :true-value="'trueValue' in field ?field.trueValue:true"
        :false-value="'falseValue' in field ?field.falseValue:false"
        :events-bus="eventsBus"
        @mouseover.native="changeBlur(true)"
        @mouseleave.native="changeBlur(false)"
        @click:append="field.type === 'password' ? handleClickPassword() : null"
        @input="handleInputChange(field, $event)"
        @change="handleInputChange(field, $event)"
        @keyup.enter="$emit('onEnter')"
        @changeCustomInput="$emit('input', $event)"
        @upload-file="handleUploadFile"
        @reload-form="$emit('reload-form',$event)"
        v-on="on"
      />
    </template>

    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<script>
  import { generateUrl, toPathObject } from '@/util/helpers'
  import { genericRequest } from '@/api/modules'
  import {
    toAttributes,
    toType,
    toCounter,
    toFullVuetifyField,
    toVuetifyValidate,
  } from '@/mappers/form'

  export default {
    name: 'CustomInput',
    filters: {
      toType,
      toCounter,
    },
    props: {
      eventsBus: Object,
      field: {
        required: true,
        type: Object,
        default: function () {
          return {}
        },
      },
      emitSubmit: {
        type: [Boolean, String],
        default: false,
      },
      statusModal: {
        type: [Boolean, String],
        default: false,
      },
      model: {
        type: Object,
        default: function () {
          return {}
        },
      },
      localFormFields: {
        type: [Boolean, Object, Array],
        default: function () {
          return {}
        },
      },
      value: {
        type: [String, Number, Boolean, Object, Array, File],
        default: false,
      },
      validate: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      outlined: {
        type: Boolean,
        default: false,
      },
      solo: {
        type: Boolean,
        default: false,
      },
      dense: {
        type: Boolean,
        default: false,
      },
      tooltip: {
        type: [String, undefined],
        default: undefined,
      },
      dataParentModel: {
        type: [Object, Boolean],
        default: function () {
          return false
        },
      },
    },
    data () {
      return {
        inputValue: null,
        showPass: false,
        loading: false,
        items: [],
        isOver: false,
      }
    },
    computed: {
      label () {
        return this.field?.labeling?.label ? this.field.labeling.label : ''
      },
      description () {
        return this.field?.labeling?.description ? this.field.labeling.description : false
      },
      vuetifyField () {
        return toFullVuetifyField(this.field.type)
      },
      attributes () {
        return toAttributes(this.field?.style)
      },
      rules () {
        return this.validate
          ? toVuetifyValidate(this.field, this.vuetifyField, this.model)
          : []
      },
      itemValue () {
        return this.field?.key ? this.field.key : 'slug'
      },
      itemText () {
        return this.field?.text ? this.field.text : 'text'
      },

      nameListEmptyField () {
        let relationLabel = 'Sin datos'
        if (this.field?.relation?.field) {
          const isLabel = this.localFormFields.find(e => e.name === this.field.relation?.field)
          if (isLabel) {
            relationLabel = `Completar ${isLabel?.labeling?.label}`
          }
        }

        return relationLabel
      },

    },
    watch: {
      value (value) {
        this.inputValue = value
        this.$emit('input', this.inputValue)
      },
      model (value) {
        this.inputValue = null
        if (this.model?.[this.field.name]) {
          this.inputValue = this.field?.object ? this.value : this.model[this.field.name]
        }
        if (!this.field.object && !this.inputValue && this.value) {
          this.inputValue = this.value
        }
        this.$emit('input', this.inputValue)
      },
    },
    mounted () {
      if (this.vuetifyField?.items) {
        this.getItems()
      }

      if (this.model?.[this.field.name]) {
        this.inputValue = this.field?.object ? this.value : this.model[this.field.name]
      }
      if (!this.field.object && !this.inputValue && this.value) {
        this.inputValue = this.value
      }
      this.$emit('input', this.inputValue)
      this.eventsBus.$on('change', this.checkFieldDepend)
      // this.eventsBus.$on('openForm', this.getItems)
    },
    methods: {
      checkFieldDepend (field) {
        const isDepends = this.field.relation?.field && this.field.relation.field === field.name && this.field.name !== field.name
        if (this.vuetifyField?.items && isDepends) {
          this.items = []
          this.getItems()
        }
      },
      handleInputChange (field, value) {
        let newValue = value
        if (value && (typeof value === 'string' || value instanceof String)) {
          newValue = value.trim()
        }

        const props = toFullVuetifyField(field.type)
        if (props.type === 'number') {
          newValue = Number(newValue)
        }

        this.inputValue = newValue
        this.$emit('input', newValue)
      },
      handleClickPassword () {
        this.showPass = !this.showPass
      },
      async getItems () {
        let response = { resources: [] }
        if (this.field?.data?.href && !this.loading) {
          this.loading = true
          try {
            const url = generateUrl({
              href: this.field.data.href,
              id: false,
              data: { action: this.field.data, data: this.model },
            })
            if (url) {
              response = await genericRequest({
                url,
                method: this.field.data.method,
                service: this.field.data.service,
              })
            } else {
              response = { resources: [] }
            }
          } catch (error) {
            console.log(error)
            response = { resources: [] }
            this.loading = false
          } finally {

          }
        } else if (this.field?.data?.list) {
          response.resources = this.field.data.list
        } else if (this.field?.data?.source) {
          response.resources = this.model ? toPathObject(this.model, this.field.data.source) : []
        }

        if (this.vuetifyField?.items && this.field?.key && !this.field?.object) {
          const fieldDataItems = response.resources.find(e => e[this.field.key] === this.model?.[this.field.name])
          if (!response.resources?.length || (this.model?.[this.field.name] && !fieldDataItems)) {
            // this.inputValue = null
            // this.$emit('input', null)
          }
        }
        this.items = response.resources
        this.loading = false
      },
      handleUploadFile (file) {
        const files = file.fileRecordsForUpload ? file.fileRecordsForUpload : []
        const sendFiles = []
        files.forEach(element => {
          sendFiles.push(element.file)
        })
        this.$emit('input', sendFiles)
      },
      changeBlur (event) {
        this.isOver = event
      },

    },
  }
</script>

<style></style>
