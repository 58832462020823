<template>
  <VContainer fluid>
    <VRow justify="center">
      <VCol
        v-if="isTitle"
        :class="localForm.class"
        cols="11"
        :md="widthMd"
        :lg="widthLg"
        :xl="widthXl"
      >
        <Title
          :back-btn="true"
          class="px-0"
          subtitle=""
          :title="localForm.label"
        />

        <div
          v-if="!!localForm.description"
          class="text-body-1"
        >
          {{ localForm.description }}
        </div>
      </VCol>
      <VCol
        v-if="widgets.length"
        class="py-md-0 d-md-flex justify-end align-center"
        cols="12"
      >
        <v-badge
          v-for="(widget,keyWidget) in widgets"
          :key="`widget_${keyWidget}`"
          bordered
          color="success"
          content="2"
          :value="widget.badge"
          overlap
        >
          <custom-btn
            ref="button"
            :attributes="{'icon':true,elevation:0}"
            outlined
            custom-class="text-none"
            :icon="widget.icon"
            :tooltip="widget.title"
            @handleClick="checkWidgets(widget.event)"
          />
        </v-badge>
      </VCol>
      <VCol
        :class="localForm.class"
        cols="11"
        :md="widthMd"
        :lg="widthLg"
        :xl="widthXl"
      >
        <VForm
          :ref="formulario"
          class=""
          :disabled="disabled || isLoading || loading"
        >
          <VRow
            v-if="localForm.fields"
            class=""
            justify="start"
            :align="localForm.align?localForm.align:'end'"
          >
            <custom-loading :loading="loading && isCustomLoading" />
            <template
              v-if="!loading"
            >
              <template
                v-for="(field, key) in localForm.fields"
              >
                <VCol
                  v-if=" String(field.hidden)!=='true'"
                  :key="key"
                  class="py-1"
                  :class="field.class"
                  cols="12"
                  :md="field.col ? field.col : '6'"
                >
                  <label
                    v-if="field.description && field.description != ''"
                    class="pl-4 mt-1 font-weight-regular darkGrey--text d-block"
                    v-html="field.description"
                  />

                  <custom-input
                    v-if="toVuetifyField(field.type)"
                    :field="field"
                    :local-form-fields="localForm.fields"
                    :model="data"
                    :value="field.property?toPathObject(data,field.property):data[field.name]"
                    validate
                    :data-parent-model="dataParentModel"
                    :tooltip="field.title"
                    :emit-submit="emitSubmit"
                    :status-modal="statusModal"
                    :events-bus="eventsBus"
                    @input="handleInputChange(field, $event)"
                    @onEnter="onEnter"
                    @reload-form="reloadForm"
                  />
                  <Component
                    :is="field.type"
                    v-else
                  >
                    {{ `${data[field.name] || field.labeling.label}` }}
                  </Component>
                </VCol>
              </template>
            </template>
            <v-skeleton-loader
              v-else
              class="w-100"
              boilerplate
              type="article, actions"
            />
          </VRow>
        </VForm>
      </VCol>
    </VRow>
    <VRow
      v-if="localForm.actions && localForm.actions.length && !loading"
      class="custom-footer"
      justify="center"
    >
      <VCol
        class="d-flex align-center pb-6 pt-12"
        cols="11"
        :md="widthMd"
        :lg="widthLg"
        :xl="widthXl"
      >
        <VRow
          :justify="localForm.actions.length===1?'end':'space-between'"
          style="width:100%"
        >
          <VCol
            v-for="(action, index) in localForm.actions"
            :key="`action-${index}`"
            cols="12"
            :md="action.col ? action.col : 'auto'"
            class="pb-1"
          >
            <custom-btn
              ref="button"
              :attributes="toAttributes(action.style)"
              :custom-class="`text-none`"
              :color="action.style ? action.style.type : ''"
              :width="action.style ? action.style.width : ''"
              :loading="isLoading || loading"
              :icon="action.style?action.style.icon:false"
              :label="action.label"
              :tooltip="action.title"
              @handleClick="handleClick(action)"
            />
          </VCol>
        </VRow>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
  import { generateUrl, toPathObject } from '@/util/helpers'
  import { paramMapped, toAttributes, toVuetifyField } from '@/mappers/form'
  import { genericRequest } from '@/api/modules'
  import { isError } from '@/api/errors'
  import { get, sync } from 'vuex-pathify'
  import Vue from 'vue'

  export default {
    name: 'CustomForm',
    components: {},
    filters: {},
    props: {
      form: {
        type: Object,
        default: function () {
          return {}
        },
      },
      isTitle: {
        type: Boolean,
        default: false,
      },

      widthMd: {
        type: [Number, String],
        default: 11,
      },
      widthLg: {
        type: [Number, String],
        default: 11,
      },
      widthXl: {
        type: [Number, String],
        default: 11,
      },
      validate: {
        type: Boolean,
        default: false,
      },

      formulario: {
        type: String,
        default: 'form',
      },

      btnEnviarTitle: {
        type: String,
        default: 'Enviar',
      },

      disabled: {
        type: Boolean,
        default: false,
      },
      isCustomLoading: {
        type: Boolean,
        default: true,
      },
      statusModal: {
        type: Boolean,
        default: false,
      },
      dataSource: {
        type: [Object, Boolean],
        default: function () {
          return false
        },
      },
      dataParentModel: {
        type: [Object, Boolean],
        default: function () {
          return false
        },
      },
      dataParams: {
        type: [Object, Boolean],
        default: function () {
          return false
        },
      },
    },

    data () {
      return {
        emitSubmit: false,
        data: {},
        loading: false,
        toPathObject,
        isLoading: false,
        toAttributes,
        toVuetifyField,
        localForm: {},
        eventsBus: new Vue(),
        widgets: [],
        originalData: {},
      }
    },
    computed: {

      params: get('route/params'),
      paramId () {
        return this.params?.id
      },

        ...sync('app', ['drawerComentarios', 'drawerBlocks']),

    },
    watch: {
      statusModal (val) {
        if (!val) {
          this.$refs[this.formulario].reset()
          this.emitSubmit = false
        } else {
          this.eventsBus.$emit('openForm')
        }
      },
      form (val) {
        if (val && !this.loading) {
          this.initialize()
        }
      },
      dataParams (val) {
        this.$refs[this.formulario].reset()
        if (val && !this.loading) {
          this.initialize()
        }
      },
    },
    mounted () {
      this.initialize()
    },
    methods: {
      checkWidgets (widget) {
        this[widget] = true
      },
      checkForm () {
        const form = this.form?.steps?.[0] ? this.form.steps[0] : {}
        const fields = []
        if (this.data && form.fields?.length) {
          form.fields.forEach(field => {
            if (field.depends?.length) {
              const responses = []
              field.depends.forEach(element => {
                if (element.values?.length) {
                  let fieldValue = this.data[element.field]
                  if ('customField' in element) {
                    fieldValue = this.data[element.customField.field]

                    if ('key' in element.customField) {
                      fieldValue = fieldValue?.[element.customField.key]
                    }
                    if ('property' in element.customField) {
                      fieldValue = toPathObject(fieldValue, element.customField.property)
                    }
                  }
                  if (element.values.includes(fieldValue)) {
                    responses.push(true)
                  } else {
                    responses.push(false)
                  }
                }
              })
              fields.push({ ...field, hidden: responses.includes(false) ? 'true' : 'false' })
            } else {
              fields.push(field)
            }
          })
        }
        form.fields = fields
        this.localForm = {}
        this.localForm = form
        this.widgets = this.form?.widgets?.length ? this.form.widgets : []
      },
      reloadForm (data) {
        const { response: { resource }, field } = data
        this.data = { ...this.data, [field.name]: resource[field.name] }
        this.checkForm()
      },
      async initialize () {
        this.loading = true
        this.data = {}
        this.checkForm()
        if (this.localForm?.source) {
          const { data: source, dataMock } = this.localForm.source

          if (source?.href) {
            let url = source.href
            if (this.params?.id || this.dataParams?.id || this.dataParams) {
              url = generateUrl({
                href: source.href,
                id: this.params?.id || this.dataParams?.id,
                data: { data: this.dataParams?.data?.item || this.dataParams, action: source },
              })
            }
            try {
              const { resource } = await genericRequest({
                url,
                method: source.method,
                service: source.service,
              })

              if (this.localForm?.fields?.length) {
                this.localForm.fields.forEach(element => {
                  if (!(element.name in resource) && 'property' in element) {
                    resource[element.name] = toPathObject(resource, element.property)
                  }
                })
              }

              this.data = resource
              this.$emit('changeTitle', resource.name || `${resource.firstname} ${resource.lastname}`)
            } catch (error) {
            } finally {

            }
          }
          if (dataMock) {
            this.data = dataMock
          }
        } else {
          if (this.form?.title) {
            this.$emit('changeTitle', this.form.title)
          }
        }
        if (this.dataSource) {
          this.data = { ...this.data, ...this.dataSource }
        }
        this.originalData = JSON.parse(JSON.stringify(this.data))
        this.loading = false
        this.$refs[this.formulario].resetValidation()
        this.emitSubmit = false
        this.$emit('loaded')
      },
      async apiValidation (field, value) {
        this.$set(field, 'error', false)
        if (field.customValidations?.length) {
          const apiValidation = field.customValidations.find(element => element.validation === 'apiValidation')
          if (apiValidation) {
            if (value?.length >= apiValidation.data.minLength) {
              const params = {}
              apiValidation.data.params.forEach(param => {
                params[param] = this.data[param]
              })
              this.isLoading = true
              const { resource } = await genericRequest({
                url: apiValidation.data.endpoint,
                method: apiValidation.data.method,
                params,
              })

              this.$set(field, 'error', !resource)
              this.isLoading = false
            }
          }
        }
      },
      handleInputChange (field, value) {
        if ('object' in field) {
          this.data[field.name] =
            this.data[field.name]
              ? { ...this.data[field.name], [field.object]: value }
              : { [field.object]: value }

          this.eventsBus.$emit('change', field)
        } else {
          if (this.data[field.name] !== value) {
            this.data[field.name] = value
            this.eventsBus.$emit('change', field)
          }
        }

        this.apiValidation(field, value)
        this.checkForm()
      },

      isDepends (field) {
        const response = true
        if (String(field.hidden) === 'true') return false

        return response
      },
      checkValidate () {
        let response = true
        this.localForm.fields.forEach(element => {
          if (element.type === 'file' && String(element.required) === 'true') {
            if (!this.data?.[element.name]) {
              response = false
            }
          }
        })
        return response
      },
      async sendForm (action) {
        let response = null
        this.emitSubmit = true

        if (!this.validate || (this.$refs[this.formulario].validate() && this.checkValidate())) {
          this.$emit('isLoading', true)
          if (action?.submit?.href) {
            this.isLoading = true
            try {
              const id = this.data.uuid || this.data.slug || this.paramId
              const url = generateUrl({ href: action.submit.href, id, data: { action, data: this.data } })

              const params = paramMapped(action?.submit?.parameters, this.data, this.localForm.fields, this.originalData)

              response = await genericRequest({
                url,
                method: action.submit.method,
                params,
                service: action.submit.service,
              })

              if (response && !isError(response.response?.status)) {
                this.$emit('submit', { data: this.data, response })

                if (action?.redirect?.href) {
                  if (action?.redirect?.service === 'local') {
                    const id = response?.resource?.uuid
                    const urlRedirect = generateUrl({ href: action.redirect.href, id, data: { action, data: response?.resource } })
                    this.$router
                      .push({ path: urlRedirect || '/' })
                      .catch((error) => {
                        console.log(error.message)
                      })
                  }

                  return
                } else {
                  this.$emit('submit', { data: this.data, response })
                }
                if (action.submit?.action) {
                  this.$emit(action.submit.action, { data: this.data, response })
                }
                if (action.submit.reset) {
                  this.$refs[this.formulario].reset()
                }
              }
            } catch (error) {
              console.log(error)
              this.isLoading = false
            } finally {
              this.isLoading = false
            }
          } else {
            this.$emit('submit', { data: this.data, response })
          }
        }
        this.$emit('isLoading', false)
      },
      handleClick (action) {
        if (!action?.submit && action?.redirect?.href) {
          if (action?.redirect?.service === 'local') {
            this.$router
              .push({ path: action.redirect.href || '/' })
              .catch((error) => {
                console.log(error.message)
              })
          }
        } else if (action.event?.action) {
          if (action.event.reset) {
            this.$refs[this.formulario].reset()
          }

          this.$emit(action.event.action, action)
        } else if (action?.submit) {
          this.sendForm(action)
        }
      },
      onEnter () {
        if (this.isActions && this.form.actions?.length) {
          const action = this.form.actions.find((e) => e.submit)
          if (action) {
            this.sendForm(action)
          }
        } else {
          this.sendForm()
        }
      },
    },
  }
</script>

<style></style>
