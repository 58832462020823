// Utilities
import { make } from 'vuex-pathify'
import { genericRequest } from '@/api/modules'
import { getProveedores, clearPassword } from '@/routes/usuarios'
import { usuariosHeaders, usuariosMapper } from '@/mappers/usuariosMapper'

const state = {
  items: [],
  headers: usuariosHeaders,
  loading: true,
  count: 0,
}

const mutations = make.mutations(state)

const actions = {

  async getData ({ commit, state }, query) {
    commit('SET_LOADING', true)
    commit('SET_COUNT', 0)
    commit('SET_ITEMS', [])

    const { resources: data } = await genericRequest({
      url: getProveedores,
      method: 'get',
    })
    if (data) {
      const dataMapped = usuariosMapper(data)
      commit('SET_COUNT', dataMapped.length)
      commit('SET_ITEMS', dataMapped)
    }
    commit('SET_LOADING', false)
  },

  async clearPass ({ commit, state }, query) {
    commit('SET_LOADING', true)
    await genericRequest({
      url: clearPassword,
      method: 'post',
      params: query,
    })

    commit('SET_LOADING', false)
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
