<template>
  <v-card
    v-bind="$attrs"
    :to="to"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
  export default {
    name: 'AppCard',
    props: {

      to: {
        type: String,
        default: null,
      },

    },
  }
</script>
