<template>
  <VContainer fluid>
    <div class="d-flex justify-space-between align-end">
      <span
        class="font-weight-negrita"
        :class="$vuetify.breakpoint.mdAndUp ? 'headline' : 'title'"
      >
        {{ title }}
      </span>
    </div>
  </VContainer>
</template>

<script>
  export default {
    name: 'Title',
    components: {},

    props: {
      title: {
        type: String,
        default: '',
      },
    },
  }
</script>

<style></style>
