<template>
  <v-btn
    solo
    dense
    text
    dark
    rounded
    class="text-none px-0"
    color="primary"
    height="auto"
    @click="back"
  >
    <v-icon
      small
      class="mr-1"
    >
      mdi-arrow-left
    </v-icon>
    <span class="text-subtitle-2">
      Volver
    </span>
  </v-btn>
</template>
<script>
  export default {
    name: 'Back',
    methods: {
      back () {
        if (this.$route.query.noGoBack) {
          this.$router.push({ path: '/' })
        } else {
          this.$router.go(-1)
        }
      },
    },
  }
</script>

<style>

</style>
