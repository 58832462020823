<template>
  <VTextField
    v-model="search"
    append-icon="mdi-magnify"
    clearable
    color="secondary"
    dense
    enclosed
    hide-details
    :label="placeholder"
    :disabled="disabled"
    :loading="disabled"
    @click:append="handleClick"
    @click:clear="handleClick('clear')"
    @keyup.enter="handleClick"
    @change="handleChange"
  />
</template>

<script>
  export default {
    name: 'Search',
    props: {
      placeholder: {
        type: [String],
        default: 'Buscar',
      },
      disabled: {
        type: [Boolean],
        default: false,
      },
    },
    data () {
      return {
        search: null,
      }
    },
    methods: {

      handleClick (action) {
        if (action === 'clear') {
          this.search = null
        }
        this.$emit('click', this.search)
      },
      handleChange () {
        this.$emit('change', this.search)
      },
    },
  }
</script>

<style></style>
