import request from '@/util/request'
import mockRequest from '@/util/mockRequest'
import qs from 'qs'
export const genericRequest = async (query) => {
  try {
    const response = await request({
      url: query.url,
      method: query.method,
      headers: query.headers,
      base: serviceMapper(query),
      ...requestMapper(query),
    })
    return response
  } catch (error) {
    return error
  }
}

export const genericMockRequest = (query) => {
  try {
    return mockRequest({
      url: `intranet/${query.url}`,
      method: query.method,
      ...requestMapper(query),
      headers: query.headers,
    })
  } catch (error) {
    return error
  }
}

const requestMapper = (query) => {
  const method = query.method?.toLowerCase()
  const dataResponse = { patch: 'data', get: 'params', post: 'data', put: 'data', delete: 'data' }
  const key = dataResponse[method]
  const param = key || 'params'

  let response = query.params || query.data

  if (method === 'patch' || method === 'put' || (method === 'post' && !('fotos' in response))) {
    response = qs.stringify(response)
  } else if (method === 'post') {
    const formData = new FormData()
    getFormData(formData, response)
    response = formData
  }

  return {
    [param]: response,
  }
}

const serviceMapper = (query) => {
  const dataResponse = {}
  const key = dataResponse[query.service?.toLowerCase()]
  const baseUrl = key || 'VUE_APP_BASE_API'
  return baseUrl
}

const getFormData = (formData, data, previousKey) => {
  if (data instanceof Object) {
    Object.keys(data).forEach(key => {
      const value = data[key]
      if (value instanceof Object && !Array.isArray(value)) {
        return getFormData(formData, value, key)
      }
      if (previousKey) {
        key = `${previousKey}[${key}]`
      }
      if (Array.isArray(value)) {
        value.forEach(val => {
          if (key !== 'photo') {
            formData.append(`${key}[]`, val)
          } else {
            formData.append(`${key}`, val)
          }
        })
      } else {
        formData.append(key, value)
      }
    })
  }
}
