
export const clientesMapper = (items) => {
    return items.map((obj) => ({
        raz_soc: obj.Razon_social,
        pedidos_cant: obj.pedidos,
        remitos_cant: obj.remitos,
        id_cliente: obj.id_cliente,

    }))
}

export const clientesHeader = [
    {
      text: 'Cliente',
      align: 'start',
      sortable: false,
      value: 'raz_soc',
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      cellClass: 'font-weight-regular greyTxt--text',

    },
    {
      text: 'Pedidos',
      align: 'center',
      value: 'pedidos_cant',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',

    },
    {
      text: 'Remitos',
      align: 'center',
      value: 'remitos_cant',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',

    },
    {
      text: '',
      align: 'end',
      value: 'actions',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',
    },
  ]
