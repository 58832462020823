
export const usuariosMapper = (usuarios) => {
    return usuarios.map((obj) => ({
        raz_soc: obj.RAZ_SOC,
        id_proveed: obj.ID_PROVEED,
        cuit: obj.CUITAFIP,
        telefono: obj.TELEFONO,
        ubicacion: obj.PROVINCIA + ', ' + obj.PARTIDO + ', ' + obj.LOCALIDAD,
        direccion: obj.CALLE + ' ' + obj.NUMERO + ' ' + obj.PISO + obj.DEPTO + ' ' + obj.CODPOS,
    }))
}

export const usuariosHeaders = [
    {
      text: 'ID',
      align: 'center',
      value: 'id_proveed',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',
    },
    {
      text: 'Razon Social',
      align: 'start',
      sortable: false,
      value: 'raz_soc',
      class: 'font-weight-medium greyTxt--text text-caption text-none',
      cellClass: 'font-weight-regular greyTxt--text',

    },
    {
      text: 'CUIT',
      align: 'center',
      value: 'cuit',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',

    },
    {
      text: 'Ubicación',
      align: 'center',
      value: 'ubicacion',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',

    },
    {
      text: 'Dirección',
      align: 'center',
      value: 'direccion',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',

    },
    {
      text: 'Teléfono',
      align: 'center',
      value: 'telefono',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',

    },
    {
      text: '',
      align: 'end',
      value: 'actions',
      sortable: false,
      class: 'font-weight-medium greyTxt--text text-caption text-none',
    },
  ]

  export const usuariosAltaHeaders = [
      {
        text: 'CUIL/CUIT',
        align: 'center',
        value: 'cuit',
        sortable: false,
        class: 'font-weight-medium greyTxt--text text-caption text-none',
      },
      {
        text: 'Nombre',
        align: 'start',
        sortable: false,
        value: 'nombre',
        class: 'font-weight-medium greyTxt--text text-caption text-none',
        cellClass: 'font-weight-regular greyTxt--text',

      },
      {
        text: 'Apellido',
        align: 'center',
        value: 'apellido',
        sortable: false,
        class: 'font-weight-medium greyTxt--text text-caption text-none',

      },
      {
        text: 'Email',
        align: 'center',
        value: 'email',
        sortable: false,
        class: 'font-weight-medium greyTxt--text text-caption text-none',

      },
      {
        text: 'Grupo',
        align: 'center',
        value: 'grupo',
        sortable: false,
        class: 'font-weight-medium greyTxt--text text-caption text-none',

      },
      {
        text: 'Teléfono',
        align: 'center',
        value: 'telefono',
        sortable: false,
        class: 'font-weight-medium greyTxt--text text-caption text-none',

      },
      {
        text: '',
        align: 'end',
        value: 'actions',
        sortable: false,
        class: 'font-weight-medium greyTxt--text text-caption text-none',
      },
    ]
