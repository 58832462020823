// Pathify
import { make } from 'vuex-pathify'

// Data
const state = {
  drawer: null,
  mini: false,
  loading: true,
  alert: false,
  alertTxt: { timeout: 500, type: 'success', txt: 'Cambios guardados' },
  modalChangePassword: false,
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
